const INITIAL_STATE = {
  list: null,
  voicelist: null,
  voicelistcount: null,
  totalcount: null
};
export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "VOICEMAIL_LIST": {
      return {
        ...state,
        list: action.payload.voicemails,
        totalcount: action.payload.total_data
      };
    }
    case "VOICEMAIL_BOX": {
      return {
        ...state,
        voicelist: action.payload.voicemailsbox,
        voicelistcount: action.payload.total_data
      };
    }

    default: {
      return state;
    }
  }
}
