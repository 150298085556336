import _ from "lodash";
const INITIAL_STATE = {
  list: null,
  list_count: null,
  single: null,
  rules: null,
  block: null
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "DID_NUMBER_LIST": {
      return {
        ...state,
        list: action.payload.numbers,
        list_count: action.payload.total_data
      };
    }
    case "SEARCH_NUMBER_LIST": {
      return {
        ...state,
        list: action.payload.numbers,
        list_count: action.payload.total_data
      };
    }
    // case "ASSIGN_UPDATE_DID_NUMBER": {
    //   return { ...state, list: action.payload};
    // }
    // case "DESTINATION_STATUS": {
    //   return { ...state, list:action.payload};
    // }
    case "ADD_DID_NUMBER": {
      return action.payload;
    }
    case "RULES_LIST_BY_ID": {
      return { ...state, rules: action.payload };
    }
    case "BLOCK_GROUP_LIST": {
      return { ...state, block: action.payload };
    }
    case "DELETE_BLOCK_GROUP": {
      return {
        ...state,
        block: [...state.block.filter(x => x.id !== action.payload)]
      };
    }
    case "ACTIVE_RULE": {
      return {
        ...state,
        rules: [
          ...state.rules.map(
            obj => [action.payload].find(o => o.id === obj.id) || obj
          )
        ]
      };
    }
    case "DEACTIVE_RULE": {
      return {
        ...state,
        rules: [
          ...state.rules.map(
            obj => [action.payload].find(o => o.id === obj.id) || obj
          )
        ]
      };
    }

    default: {
      return state;
    }
  }
}
