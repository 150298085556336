const initialState = {
  queue: [],
  agent: [],
  monitor: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "QUEUE":
      return { ...state, queue: action.payload };
    case "AGENT":
      return { ...state, agent: action.payload };
    case "MONITOR":
      return { ...state, monitor: action.payload };

    default:
      return state;
  }
};
