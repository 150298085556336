import _ from "lodash";
const INITIAL_STATE = {
  list: null,
  single: null,
  buyer: null,
  extlist: null,
  destination_type: null
};
export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "CAMPAIGN_LIST": {
      return { ...state, list: action.payload };
    }
    case "DELETE_CAMPAIGN": {
      return {
        ...state,
        list: [...state.list.filter(x => x.QueueID !== action.payload)]
      };
    }
    case "INDIVIDUAL_CAMPAIGN": {
      return { ...state, single: action.payload };
    }
    case "BUYER_LIST_CAMPAIGN": {
      return { ...state, buyer: action.payload };
    }
    case "ADD_BUYER": {
      return {
        ...state,
        buyer: [...state.buyer, action.payload]
      };
    }

    case "DELETE_BUYER": {
      return {
        ...state,
        buyer: [...state.buyer.filter(x => x.uniqueid !== action.payload)]
      };
    }
    case "DESTINATION_TYPE": {
      return {
        ...state,
        destination_type: action.payload.map(x => ({
          id: x.id,
          destination: x.destination,
          value: x.value
        }))
      };
    }
    // case "DEACTIVATE_BUYER": {
    //   return {
    //     ...state,
    //     buyer: _.unionBy([action.payload], state.buyer, "uniqueid")
    //   };
    // }
    case "EXTENSION_LIST_BUYER": {
      return { ...state, extlist: action.payload };
    }
    case "ADD_CAMPAIGN": {
      return { ...state, list: [action.payload.campaign, ...state.list] };
    }
    case "UPDATE_CAMPAIGN": {
      return {
        ...state,
        list: _.unionBy([action.payload.campaign], state.list, "QueueID")
      };
    }
    default: {
      return state;
    }
  }
}
