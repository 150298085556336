import _ from "lodash";
const INITIAL_STATE = {
  list: null,
  countlist: null
};
export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "BLOCK_LIST": {
      return {
        ...state,
        list: action.payload.rules,
        countlist: action.payload.total_data
      };
    }
    case "ACTIVE_BLOCK_RULE": {
      return {
        ...state,
        list: _.unionBy([action.payload], state.list, "id")
      };
    }
    case "DEACTIVE_BLOCK_RULE": {
      return {
        ...state,
        list: _.unionBy([action.payload], state.list, "id")
      };
    }
    case "DELETE_BLOCK_RULE": {
      return {
        ...state,
        list: [...state.list.filter(x => x.id !== action.payload)]
      };
    }
    case "UNBLOCK_LIST": {
      return {
        ...state,
        outbound: action.payload.rules
      };
    }
    default: {
      return state;
    }
  }
}
