const initialState = {
  queueCallCount: 0,
  extnCallCount: 0,
  miscCallCount: 0,
  waitCallCount: 0
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "QUEUE_CALL":
      return { ...state, queueCallCount: action.payload };
    case "EXTENSION_CALL":
      return { ...state, extnCallCount: action.payload };
    case "MISCELLANEOUS_CALL":
      return { ...state, miscCallCount: action.payload };
    case "WAITING_CALL":
      return { ...state, waitCallCount: action.payload };

    default:
      return state;
  }
};
