import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./reducers";
const environment = process.env.REACT_APP_NODE_ENV;
const inititalState = {
  auth: {
    authenticated: {
      token: localStorage.getItem("token"),
      isAuth: localStorage.getItem("isAuth"),
      account: localStorage.getItem("account"),
      userType: localStorage.getItem("userType"),
      valid: localStorage.getItem("valid"),
      user: localStorage.getItem("user")
    }
  }
};
let composeEnhancers;
if (environment === "development") {
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
} else {
  composeEnhancers = compose;
}

const store = createStore(
  rootReducer,
  inititalState,
  composeEnhancers(applyMiddleware(thunk))
);
export default store;
