const INITIAL_STATE = {
  list: null,
  countlog: 0
};
export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "LOG_LIST": {
      return {
        ...state,
        list: action.payload.log_details,
        countlog: action.payload.total_data
      };
    }
    default: {
      return state;
    }
  }
}
